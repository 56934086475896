<template>
  <div class="d-flex kt-wizard-v4" id="kt_wizard_v4" data-ktwizard-state="step-first">
    <!--begin: Form Wizard Nav -->
    <div class="kt-wizard-v4__nav">

      <!--doc: Remove "kt-wizard-v4__nav-items--clickable" class and also set 'clickableSteps: false' in the JS init to disable manually clicking step titles -->
      <div class="kt-wizard-v4__nav-items kt-wizard-v4__nav-items--clickable">
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step" data-ktwizard-state="current">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              1
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.ONE.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.ONE.DESC") }}
              </div>
            </div>
          </div>
        </div>
        <div class="kt-wizard-v4__nav-item" data-ktwizard-type="step">
          <div class="kt-wizard-v4__nav-body">
            <div class="kt-wizard-v4__nav-number">
              2
            </div>
            <div class="kt-wizard-v4__nav-label">
              <div class="kt-wizard-v4__nav-label-title">
                {{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.TWO.TITLE") }}
              </div>
              <div class="kt-wizard-v4__nav-label-desc">
                {{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.TWO.DESC") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end: Form Wizard Nav -->
    <portlet body-fit body-class="border-start-0" class="border-start-0">
      <template v-slot:body>
        <div class="kt-grid">
          <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v4__wrapper">

            <!--begin: Form Wizard Form-->

            <div class="kt-form" id="kt_form">
              <!--begin: Form Wizard Step 1-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.TWO.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-ug-title" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.title_ug')" label-for="ug-title">
                          <b-form-input id="ug-title" :class="[direction_rtl]" v-model="formData['zh-UG'].title" :state="validateState('title', 'zh-UG')" aria-describedby="ug-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.title_ug')" />
                          <b-form-invalid-feedback id="ug-title-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.title_ug') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="9">
                        <b-form-group id="input-group-ug-describe" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.describe_ug')" label-for="ug-describe">
                          <b-form-input :class="[direction_rtl]" id="ug-describe" v-model="formData['zh-UG'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.describe_ug')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-cn-title" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.title_cn')" label-for="cn-title">
                          <b-form-input id="cn-title" :class="[direction_ltr]" v-model="formData['zh-CN'].title" :state="validateState('title', 'zh-UG')" aria-describedby="cn-title-live-feedback" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.title_cn')" />
                          <b-form-invalid-feedback id="cn-title-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.title_cn') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="9">
                        <b-form-group id="input-group-cn-describe" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.describe_cn')" label-for="cn-describe">
                          <b-form-input :class="[direction_ltr]" id="cn-describe" v-model="formData['zh-CN'].describe" type="text" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.describe_cn')" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 1-->

              <!--begin: Form Wizard Step 2-->
              <div class="kt-wizard-v4__content other" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div class="kt-heading kt-heading--md">{{ $t("COMPONENTS.FORMS.MERCHANT_FORM.FORM.STEP.ONE.DESC") }}</div>
                <div class="kt-form__section kt-form__section--first">
                  <div class="kt-wizard-v4__form">
                    <b-row>
                      <b-col sm="6" md="3">
                        <b-form-group id="input-group-mch_id" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.mch_id')" label-for="mch_id">
                          <b-form-input id="mch_id" :class="[direction_ltr]" v-model="formData.mch_id" :state="validateState('mch_id')" aria-describedby="mch_id-live-feedback" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.mch_id')" />
                          <b-form-invalid-feedback id="mch_id-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.mch_id') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" md="4">
                        <b-form-group id="input-group-key" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.key')" label-for="key">
                          <b-input-group>
                            <b-input-group-prepend>
                              <b-input-group-text>
                                {{ formData.key.length }}
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input id="key" trim :class="[direction_ltr]" v-model="formData.key" :state="validateState('key')" aria-describedby="key-live-feedback" :placeholder="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.key')" />
                            <b-input-group-append>
                              <b-button @click="formData.key = generateKey()">{{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.generate') }}</b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-invalid-feedback id="key-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.key') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col sm="12" md="3">
                        <b-form-group aria-describedby="cert_path-live-feedback" id="input-group-cert_path" :description="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.cert_path')" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.cert_path')" label-for="cert_path">
                          <image-uploader v-model="formData.cert_path" :btn-label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.cert_uploader')" :upload-form="{strategy: 'cert'}" accepted-file-types="application/x-x509-ca-cert" />
                          <b-form-invalid-feedback id="cert_path-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.cert_path') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="3">
                        <b-form-group aria-describedby="key_path-live-feedback" id="input-group-key_path" :description="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.PLACEHOLDER.key_path')" :label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.key_path')" label-for="key_path">
                          <image-uploader v-model="formData.key_path" :btn-label="$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.LABEL.cert_uploader')" :upload-form="{strategy: 'cert'}" accepted-file-types="application/x-x509-ca-cert" />
                          <b-form-invalid-feedback id="key_path-live-feedback">
                            {{ $t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.FEEDBACK.key_path') }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
              <!--end: Form Wizard Step 2-->

              <div class="kt-wizard-v4__other">
                <b-row>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-9" :label="$t('COMMON.FORM.LABEL.sort')" label-for="sort">
                      <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" :placeholder="$t('COMMON.FORM.PLACEHOLDER.sort')" />
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="3">
                    <b-form-group id="input-group-is_show" :label="$t('COMMON.FORM.LABEL.is_show')" label-for="is_show">
                      <b-form-radio-group
                        id="is_show"
                        v-model="formData.is_show"
                        :options="yesOrNot"
                        buttons />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--begin: Form Actions -->
              <div class="kt-form__actions">
                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                  {{ $t("COMMON.FORM.BUTTONS.PREV") }}
                </button>
                <button class="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit" @click="onSubmit">
                  {{ $t("COMMON.FORM.BUTTONS.SUBMIT") }}
                </button>
                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                  {{ $t("COMMON.FORM.BUTTONS.NEXT") }}
                </button>
              </div>
              <!--end: Form Actions -->
            </div>
            <!--end: Form Wizard Form-->
          </div>
        </div>
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import ImageUploader from "@v@/partials/layout/ImageUploader";
  import KTWizard from "@/assets/js/wizard";
  import KTUtil from "@/assets/js/util";
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import directionMixin from "@m@/common/direction.mixin";
  import merchantMixin from "@m@/merchant.mixin";
  export default {
    name: "MerchantForm",
    components: {Portlet, ImageUploader},
    props: {
      action: {
        type: String,
        default: 'create'
      },
      id: {
        type: [String, Number],
      },
    },
    mixins: [merchantMixin, validationMixin, validateStateMixin, sweetAlertMixin, directionMixin],
    created() {
      if (this.action === 'edit'){
        this.getMerchant(this.id)
          .then(res=>{
            this.formData = Object.assign({}, this.formData, res.data);
          })
          .catch(err=>{
            console.info('getMerchant.err: ', err);
          })
      }
    },
    mounted() {
      this.wizardHandler();
    },
    validations(){
      let validates = {
        mch_id: { required },
        key: { required },
        // cert_path: { required },
        // key_path: { required },
      };
      validates["zh-UG"] = {
        title: { required }
      };
      validates["zh-CN"] = {
        title: { required }
      }
      return {
        formData: validates
      }
    },
    data() {
      return {
        loading: false,
        wizard: null,
        yesOrNot: [
          { text: this.$t('COMMON.FORM.OPTIONS.YES'), value: true },
          { text: this.$t('COMMON.FORM.OPTIONS.NO'), value: false },
        ],
        formData: {
          mch_id: '',
          key: '',
          cert_path: '',
          key_path: '',
          sort: null,
          is_show: true,
          "zh-UG": {
            title: "",
            describe: "",
          },
          "zh-CN": {
            title: "",
            describe: "",
          }
        }
      }
    },
    methods: {
      wizardHandler(){
        // Initialize form wizard
        this.wizard = new KTWizard('kt_wizard_v4', {
          startStep: 1, // initial active step number
          clickableSteps: true  // allow step clicking
        });
        this.$nextTick(function () {
          const self = this;
          // Validation before going to next page
          this.wizard.on('beforeNext', function(wizardObj) {
            // console.info('beforeNext.wizardObj: ', wizardObj);
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          this.wizard.on('beforePrev', function(wizardObj) {
            let { currentStep } = wizardObj;
            self.stepValidHandler(currentStep, wizardObj);
          });

          // Change event
          this.wizard.on('change', function(wizard) {
            KTUtil.scrollTop();
          });
        });
      },
      validHandler(formName = 'title', wizardObj){
        if( formName ){
          this.$v.formData[formName].$touch();
        }else {
          this.$v.formData.$touch();
        }
        // if (this.$v.formData[formName].$anyError === true) {
        if (this.$v.formData.$anyError === true) {
          wizardObj.stop();
          this.warning({message: this.$t('COMPONENTS.FORMS.ARTICLE_RAISE_FORM.TIPS.VALIDATION_TIPS')});
          return false;
        }else {
          return true;
        }
      },
      stepValidHandler(currentStep, wizardObj){
        switch (currentStep) {
          case 1:
            this.validHandler("zh-UG", wizardObj);
            this.validHandler("zh-CN", wizardObj);
            break;
          case 2:
            this.validHandler(null, wizardObj);
            break;
        }
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: this.$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.TIPS.VALIDATION_TIPS')});
        }
        if (this.action === 'create'){
          this.storeMerchant(this.formData)
            .then(()=> {
              this.$router.replace({ name: `merchant.home` });
            }).catch(err=>{
              console.info('storeMerchant.err: ', err)
            })
        }else if ( this.action === 'edit' ){
          this.updateMerchant(this.id, this.formData)
            .then(()=>{
              this.success({ message: this.$t('COMPONENTS.FORMS.MERCHANT_FORM.FORM.TIPS.EDIT_SUCCESS')})
            })
            .catch(err=>{
              console.info('updateMerchant.err: ', err)
            })
        }
      },
      generateKey(len = 32){
        let randomStr = ()=>{
          const str = 'abcdefjhijklmnopqrstuvwxyz23456789ABCDEFJHIJKLMNOPQRSTUVWXYZ';
          let index = Math.floor(Math.random() * (str.length - 1));
          return str[index];
        }
        let key = '';
        while (key.length < len){
          key += randomStr();
        }
        return key;
      },
    }
  }
</script>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-4";
</style>
